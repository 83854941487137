<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">支店編集</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form-branch />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormBranch from '../../../components/FormComponents/Branch'

export default {
  components: {
    FormBranch,
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
